form {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: auto;
}
form .signUpForm h2:first-child {
    padding-top: 32px;
}
form ul {
    list-style-type: none;
    font-family: CircularSpotifyBook;
    /* letter-spacing: .5px; */
}
form .svg {
    height: 27px;
}
form h1, form h2,form  h4, form input, form label, form button, form select, form option {
    font-family: CircularSpotifyMedium;
}
form h1, form h2, form h4 {
    margin: 16px 0;
}
form label {
    font-family: CircularSpotifyBold;
    padding: 8px 0;
}
form input[type="radio"] {
    margin: 4px 6px 7px 3px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
}
form span.radio {
    margin: 0 20px 0 0px;
}
form h1,form  h2,form  h4 {
    text-align: center;
}
form.signUpForm h1 {
    font-size: 32px;
    letter-spacing: -2px;
    color: #000000;
}
form h4 {
    font-size: 11px;
    font-family: CircularSpotifyBook;
}
form h2 {
    font-size: 16px;
    font-family: CircularSpotifyBook;
}
form label,form  li {
    font-size: 14px;
}
form > label {
    padding: 24px 0 0 0 ;
}
form a {
    color: #74E167;
}
form li {
    color: #ff0000;
}
button {
    background-color: #6FD862;
    font-size: 18px;
    border-width: 0;
    border-radius: 500px;
    height: 56px;
    width: 158.91px;
    margin: auto;
    font-family: CircularSpotifyBold;
}
button:hover {
    background-color: #74E167;
    transform: scale(1.03);
}
form input[type="radio"] {
    color: #74E167;
 }
 form .textInput, form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 48px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    padding-inline: 14px;
    font-size: 16px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 4px;
}
form input:focus {
    outline: none;
    border-width: 2px;
    border-color: #000000;
}
form .red:focus {
    border-color: #ff0000;
}
form .red {
    border-color: #ff0000;
}
form .emailError,form  .passwordError,form  .nameError,form  .monthError,form  .dayError,form  .yearError {
    font-family: CircularSpotifyBook;
    font-size: 14px;
    letter-spacing: -.1px;
    padding-inline: 8px;
    color:#ff0000;
}
form select:invalid, form option[value=""] {
    color: gray;
}
form .birthDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
form .birthDate > label {
    display: flex;
    flex-direction: column;
}
form .monthInput {
    width: 45%;
}
form .dayInput {
    width: 20%;
}
form .yearInput {
    width: 25%;
}

.signUpBody {
    background-color: #ffffff;
}

form .book {
    font-family: CircularSpotifyBook;
    padding-inline: 8px;
    font-size: 14px;
}

form .oughtifyLogo {
    font-family: CircularSpotifyMedium;
    font-size: 28px;
    color: #000000;
    letter-spacing: -2px;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
}
form .oughtifyLogo i {
    font-size: 32px;
    color: #000000;
}


form.signUpForm h4:hover {
    color: #000000;
    text-decoration: none;
    cursor: default;
}

