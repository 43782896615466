.library {
    position: relative;
    height: 100%;
    min-width: 280px;
    background-color: #121212;
    /* border-radius: 8px; */
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow-y: scroll;
    margin: 0;
    box-sizing: border-box;
    /* flex: 1 1 auto; */
}

.library > ul {
    /* height: 64px; */
    /* width: ; */
    /* padding: 8px; */
    margin: 8px;
    display: grid;
    width: calc(100% - 16px);
}
.library .albumImage  {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 4px;
    overflow: hidden;
    min-width: 50px;
}
.library .fourImages  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    flex-direction: column;
    justify-content: space-around;
}
.library > ul > li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    border-radius: 4px;
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
.library > ul > li.cannotAddTo {
    opacity: 0.5;
}
.library > ul > li.greenBorder {
    /* border: 1px solid #1ED760; */
    box-shadow:0px 0px 0px 2px #1ED760 inset;
}
.library > ul > li:hover {
    background-color: #1A1A1A;
}


.library > ul > li .onethPlaylistImageStandin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    height: 50px;
    width: 50px;
    background-size: cover;
}

.library > ul > li .fourthPlaylistImageStandin {
    height: 25px;
    width: 25px;
    background-size: cover;
}


.library img {
    height: 50px;
    border-radius: 4px;
}
.library .albumInfo{
    padding-left: 12px;
    width: 100%;
    /* flex: 1 0 auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 8px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.library h3, .library h4 {
    max-width: 95%;
    font-family: CircularSpotifyLight;
    font-weight: bold;
    letter-spacing: .5px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.library h3 {
    color: #FFFFFF;
    font-size: 16px;
    margin: 0;
}
.library h4 {
    color: #A7A7A7;
    font-size: 13px;
    margin: 0;
}