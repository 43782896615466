
div.home .playlistGridHeader > a {
    font-family: CircularSpotifyMedium;
    font-size: 32px;
}

div.home .searchGrid li {
    font-family: CircularSpotifyBook;
    width: inherit;
}

div.home .searchGrid .imageStandIn {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
}

div.home .searchGrid .artistName,
div.home .searchGrid .playlistTitle,
div.home .searchGrid .albumTitle,
div.home .searchGrid .songTitle {
    font-size: 20px;
}

div.home .searchGrid .songArtistName,
div.home .searchGrid .albumArtistName,
div.home .searchGrid .playlistUserName {
    font-size: 14px;
    color: #B3B3B3;
}

div.home .searchGrid .fourth {
    margin-top: -15px;
}


div.home .searchGrid .albumImage  {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1;
    margin: 0;
}
div.home .searchGrid .fourImages  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    flex-direction: column;
    justify-content: space-around;
}

div.home .searchGrid  .onethPlaylistImageStandin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    width: 100%;
    aspect-ratio: 1;
    background-size: cover;
}

div.home .searchGrid  .fourthPlaylistImageStandin {
    height: 100%;
    width: 100%;
    background-size: cover;
}