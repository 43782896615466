div.home .albumShowTop {
    position: absolute;
    width: inherit;
    height: 340px;
    z-index: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
div.home .albumImage > img {
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 1);
}
div.home .albumImage > img:hover {
    transform: scale(1.03);
}
div.home .albumImage {
    margin: 0 0 30px 30px;
}
div.home .albumHeaders {
    margin: 0 0 30px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}
.albumHeaders h4, h5, h1, .albumHeaders a {
    text-decoration: none;
    color: #FFFFFF;
    /* box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 1); */
    background: transparent;
}
.albumHeaders h1 {
    font-family: CircularSpotifyMedium;
    font-size: 80px;
    letter-spacing: -4px;
    white-space: nowrap;
}
.albumHeaders h4, .albumHeaders a {
    font-family: CircularSpotifyBook;
    font-size: 14px;
}
.albumHeaders h5 {
    font-family: CircularSpotifyLight;
    font-size: 14px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.albumHeaders a:hover {
    text-decoration: underline;
}
.albumHeaders h5 > img {
    width: 24px;
    height: auto;
    border-radius: 500px;
    margin-top: -2px;
    margin-right: 2px;
}
.albumHeaders .albumLength {
    font-family: CircularSpotifyLight;
    color: #FFFFFF;
}
div.home .opaqueBkgd-2 {
    background-image: linear-gradient(to bottom, rgb(247, 193, 121), rgba(18, 18, 18, 1));
    background-size: 100% 680px;
    background-repeat: no-repeat;
}
.trackList {
    position: relative;
    width: calc(100% - 44px);
    padding: 22px;
    margin: auto;
    background-color: rgba(18, 18, 18, .3);
    margin-top: 340px;
    color: #B3B3B3;
    font-family: CircularSpotifyLight;
    font-size: 14px;
}
.trackList span.bigButtons {
    vertical-align: middle;
}
.trackList button.bigPlay {
    width: 56px;
    height: 56px;
    font-size: 24px;
    vertical-align: middle;
}
.trackList button.bigPlay > i {
    padding-left: 2px;
}
.trackList .bigHeart {
    padding-left: 24px;
    font-size: 32px;
    width: 56px;
    height: 56px;
    vertical-align: middle;
}
.trackList .bigHeart > i:hover {
    color: #FFFFFF;
}
.trackList .bigDots {
    padding-left: 24px;
    font-size: 24px;
    vertical-align: middle;
}
.trackList .bigDots > i:hover {
    color: #FFFFFF;
}
.trackList hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #B3B3B3;
    opacity: .2;
}
.trackList > table {
    width: 100%;
    padding: 16px;
}

.trackList > table > tr{
    width: 100%;
    display: grid;
    grid-template-columns: .5fr 16fr .5fr 1fr .35fr;
    align-items: center;
    gap: 16px;
    height: 56px;
    border-radius: 4px;
}
.trackList > table > tr:hover {
    background-color: #282828;
}
.trackList > table > tr:first-child {
    background-color: transparent;
    height: 36px;
    align-items: flex-end;
}

.trackList > table > tr > td:first-child{
    font-size: 16px;
    text-align: right;
}
.trackList > table > tr > td:nth-child(4){
    text-align: right;
}
.trackList > table > tr > td:last-child{
    text-align: left;
    padding-right: 16px;
}

.trackList > table ul > li:first-child{
    font-family: CircularSpotifyLight;
    font-size: 16px;
    color: #FFFFFF;
}
.trackList > table ul a{
    font-family: CircularSpotifyLight;
    font-size: 14px;
    color: #B3B3B3;
    text-decoration: none;
}
.trackList > table ul a:hover{
    text-decoration: underline;
}
.trackList > table ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
div.home .moreBy ul:hover {
    background-color: #282828;
}

.moreBy h2  {
    font-family: CircularSpotifyMedium;
    font-size: 24px;
    text-align: left;
    color: #FFFFFF;
    padding-left: 16px;
}

.moreBy {
    margin-top: 16px;
    margin-left: -22px;
    margin-right: -22px;
}

.trackList > table > tr.selectedTrack {
    background-color: #5c5c5c;
    border-radius: 4px;
}
.trackList > table > tr > td:last-child{
    position: relative;
}

.trackList .hiddenUl {
    position: absolute;
    background-color: #282828;
    width: 213.84px;
    right: 8px;
    bottom: 12px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    z-index: 2;
}
.trackList .hiddenUl > li {
    padding: 12px 8px 12px 12px;
    border-radius: 4px;
}
.trackList .hiddenUl > li.barUnder {
    border-bottom: 1px solid rgba(61, 61, 61, .8);
}
.trackList .hiddenUl > hr {
    padding: 0;
}
.trackList .hiddenUl > li:hover {
    background-color: #3d3d3d;
}
.trackList .hiddenUl > li > span {
    cursor: default;
    display: flex;
    flex-direction: row;
    /* justify-content:left; */
    align-items: center;
    vertical-align: middle;
    gap: 12px;
}

.trackList .hiddenUl .hiddenPlaylistUl {
    position: absolute;
    background-color: #282828;
    width: 213.84px;
    right: 213.84px;
    height: 300px;
    bottom: 0;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    z-index: 2;
    overflow-y: scroll;
}
.trackList .hiddenUl .hidden {
    display: none;
}

.trackList .hiddenUl .hiddenPlaylistUl > li {
    cursor: default;
    padding: 12px 8px 12px 12px;
    border-radius: 4px;
}
.trackList .hiddenUl .hiddenPlaylistUl > li:hover {
    background-color: #3d3d3d;
}