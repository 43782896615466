

.albumGrid {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; */
    margin: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(180px, 1fr));
}
.albumName {
    font-size: 16px;
    font-family: CircularSpotifyBook;
    color: #FFFFFF;
}
.albumYear {
    font-family: CircularSpotifyBook;
    font-size: 14px;
    color: #B3B3B3;
}