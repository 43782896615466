div.home .playlistShowTop {
    position: absolute;
    width: inherit;
    height: 340px;
    z-index: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
div.home .playlistImage > div.fourPlaylistImages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 200px;
    height: 200px;
    box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 1);
    background: #282828;
    border-radius: 5px;
    overflow:hidden;
}
div.home .playlistImage > div.fourPlaylistImages > div.fourthImage {
    width: 100%;
    background-size: cover;
}
div.home .playlistImage > div.onePlaylistImage {
    display: grid;
    width: 200px;
    height: 200px;
    box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 1);
    background: #282828;
    border-radius: 5px;
    overflow:hidden;
}
div.home .playlistImage > div.onePlaylistImage > div.onethImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
}
div.home .playlistImage > div.onePlaylistImage  div.centerOfOne {
    font-size: 100px;
}
div.home .playlistImage {
    position: relative;
    margin: 0 0 30px 30px;
    font-family: CircularSpotifyMedium;
    color: #FFFFFF;
    border-radius: 5px;
    overflow: hidden;
}
div.home .playlistImage div.workingImageUrl {
    width: 200px;
    height: 200px;
    background-size: cover;
    align-items: center;
    justify-content: center;
}
div.home .editPlaylistModal .playlistImage div.workingImageUrl {
    width: 180px;
    height: 180px;
    background-size: cover;
    align-items: center;
    justify-content: center;
}
div.home .playlistImage div.changePhotoOverlay {
    display: none; 
}
div.home .playlistImage:hover div.changePhotoOverlay {
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:rgb(0,0,0,.5);
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
}
div.home .playlistImage h4 {
    font-size: 15px;
    text-decoration: none;
    cursor: default;
}
div.home .playlistHeaders {
    margin: 0 0 30px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}
.playlistHeaders h4, h5, h1, a {
    text-decoration: none;
    color: #FFFFFF;
    /* box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 1); */
    background: transparent;
}
.playlistHeaders h1 {
    font-family: CircularSpotifyMedium;
    font-size: 55px;
    letter-spacing: -2px;
    white-space: nowrap;
    cursor: pointer;
}
.playlistHeaders h4, a {
    font-family: CircularSpotifyBook;
    font-size: 14px;
}
.playlistHeaders h5 {
    font-family: CircularSpotifyLight;
    font-size: 14px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.playlistHeaders a:hover {
    text-decoration: underline;
}
.playlistHeaders h5 > img {
    width: 24px;
    height: auto;
    border-radius: 500px;
    margin-top: -2px;
    margin-right: 2px;
}
.playlistHeaders .playlistLength {
    font-family: CircularSpotifyLight;
    color: #FFFFFF;
}
div.home .opaqueBkgd-2 {
    background-image: linear-gradient(to bottom, rgb(247, 193, 121), rgba(18, 18, 18, 1));
    background-size: 100% 680px;
    background-repeat: no-repeat;
}
.playlistTrackList {
    position: relative;
    width: calc(100% - 44px);
    padding: 22px;
    margin: auto;
    background-color: rgba(18, 18, 18, .3);
    margin-top: 340px;
    color: #B3B3B3;
    font-family: CircularSpotifyLight;
    font-size: 14px;
}
.playlistTrackList span.bigButtons {
    vertical-align: middle;
}
.playlistTrackList button.bigPlay {
    width: 56px;
    height: 56px;
    font-size: 24px;
    vertical-align: middle;
}
.playlistTrackList button.bigPlay > i {
    padding-left: 2px;
}
.playlistTrackList .bigHeart {
    padding-left: 24px;
    font-size: 32px;
    width: 56px;
    height: 56px;
    vertical-align: middle;
}
.playlistTrackList .bigHeart > i:hover {
    color: #FFFFFF;
}
.playlistTrackList .bigDots {
    position: relative;
    padding-left: 24px;
    font-size: 24px;
    vertical-align: middle;
}
.playlistTrackList .bigDots > i:hover {
    color: #FFFFFF;
}
.playlistTrackList hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #B3B3B3;
    opacity: .2;
}
.playlistTrackList > table {
    width: 100%;
    padding: 16px;
}

.playlistTrackList > table > tr {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: .5fr 6fr 5fr 5fr .5fr 1fr .35fr;
    align-items: center;
    gap: 16px;
    height: 56px;
    border-radius: 4px;
}
.playlistTrackList > table > tr:hover {
    background-color: #282828;
}
.playlistTrackList > table > tr.selectedTrack {
    background-color: #5c5c5c;
    border-radius: 4px;
}
.playlistTrackList > table > tr.greenBorderTop::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust this value to control the thickness of the top border */
    background-color: #1ED760;
}
.playlistTrackList > table > tr.greenBorderBottom::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust this value to control the thickness of the top border */
    background-color: #1ED760;
}
.playlistTrackList > table > tr:not(.selectedTrack) + .selectedTrack, .playlistTrackList > table > hr + tr.selectedTrack{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* background-color: yellowgreen; */
}

.playlistTrackList > table > tr.selectedTrack + .selectedTrack {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;    /* background-color: yellow; */
}
.playlistTrackList > table > tr.selectedTrack + .selectedTrack:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* background-color: orange; */
}
.playlistTrackList > table > tr:first-child {
    background-color: transparent;
    height: 36px;
    align-items: flex-end;
}

.playlistTrackList > table > tr > td:first-child{
    font-size: 16px;
    text-align: right;
}
.playlistTrackList > table > tr > td:nth-child(4){
    text-align: right;
}
.playlistTrackList > table > tr > td:last-child{
    text-align: left;
    padding-right: 16px;
}

.playlistTrackList > table > tr > td:nth-child(2), .playlistTrackList > table > tr > td:nth-child(3) {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.playlistTrackList > table .playlistImageColumn ul > li:first-child{
    font-family: CircularSpotifyLight;
    font-size: 16px;
    color: #FFFFFF;
}
.playlistTrackList > table ul a{
    font-family: CircularSpotifyLight;
    font-size: 14px;
    color: #B3B3B3;
    text-decoration: none;
}
.playlistTrackList > table ul a:hover{
    text-decoration: underline;
}
.playlistTrackList > table ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.playlistTrackList .playlistImageColumn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.playlistTrackList .playlistImageColumn img {
    width: 40px;
}
.playlistTrackList .playlistImageColumn ul {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.playlistTrackList .playlistImageColumn li {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.playlistTrackList a {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.playlistTrackList a {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.playlistTrackList .hiddenUl {
    position: absolute;
    background-color: #282828;
    width: 213.84px;
    right: 8px;
    bottom: 12px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    z-index: 2;
}
.playlistTrackList .hiddenUl > li {
    padding: 12px 8px 12px 12px;
    border-radius: 4px;
}
.playlistTrackList .hiddenUl > li.barUnder {
    border-bottom: 1px solid rgba(61, 61, 61, .8);
}
.playlistTrackList .hiddenUl > hr {
    padding: 0;
}
.playlistTrackList .hiddenUl > li:hover {
    background-color: #3d3d3d;
}
.playlistTrackList .hiddenUl > li > span {
    cursor: default;
    display: flex;
    flex-direction: row;
    /* justify-content:left; */
    align-items: center;
    vertical-align: middle;
    gap: 12px;
}

.playlistTrackList .hiddenUl .hiddenPlaylistUl {
    position: absolute;
    background-color: #282828;
    width: 213.84px;
    right: 213.84px;
    height: 300px;
    bottom: 0;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    z-index: 2;
    overflow-y: scroll;
}
.playlistTrackList .hiddenUl .hidden {
    display: none;
}

.playlistTrackList .hiddenUl .hiddenPlaylistUl > li {
    cursor: default;
    padding: 12px 8px 12px 12px;
    border-radius: 4px;
}
.playlistTrackList .hiddenUl .hiddenPlaylistUl > li:hover {
    background-color: #3d3d3d;
}

.playlistTrackList > table > tr > td:last-child{
    position: relative;
}

.editPlaylistModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 384px;
    width: 524px;
    z-index: 6 !important;
    background-color: #282828;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: CircularSpotifyMedium;
}

.editPlaylistModal > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    padding: 24px;
}
.editPlaylistModal > div:first-child > i {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #BEBEBE;
    font-size: 16px;
    padding: 8px;
    width: 16px;
    height: 16px;
    align-items: center;
}
.editPlaylistModal > div:first-child > i:hover {
    color: #BEBEBE;
    background-color: #3d3d3d;
    border-radius: 500px;
}

.editPlaylistModal > .editModalInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin: 0 24px;
}

.editPlaylistModal > .editModalInputs > .editModalTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}
.editPlaylistModal > .editModalInputs > .editModalTitle > input {
    width: 256px;
    background-color: #3d3d3d;
    border-style: none;
    padding: 0px 12px;
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 4px;
}
.editPlaylistModal > .editModalInputs > .editModalTitle > input:first-child {
    height: 40px;
}
.editPlaylistModal > .editModalInputs > .editModalTitle > input:nth-child(2) {
    height: 124px;
}
.editPlaylistModal > .editModalSave {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    margin: 8px 24px;
}
.editPlaylistModal > .editModalSave > button {
    background-color: #FFFFFF;
    width: 99.72px;
    height: 48px;
    color: #000000;
    font-size: 16px;
    margin: 0;
}
.editPlaylistModal  input.hiddenFileInput {
    display: none;
}
.editPlaylistModal > div:last-child {
    margin: 0 24px 24px;
    font-size: 12px;
}
.editPlaylistModalBkgd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
}

div.home .editPlaylistModal .playlistImage > div.fourPlaylistImages {
    display: grid;
    width: inherit;
    height: inherit;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 1);
    background: #282828;
    border-radius: 5px;
    overflow:hidden;
}
div.home .editPlaylistModal .playlistImage > div.fourPlaylistImages > div.fourthImage {
    width: 100%;
    background-size: cover;
}
div.home .editPlaylistModal .playlistImage > div.onePlaylistImage {
    display: grid;
    width: 180px;
    height: 180px;
    box-shadow: 0px 2px 15px -6px rgba(0, 0, 0, 1);
    background: #282828;
    border-radius: 5px;
    overflow:hidden;
}
div.home .editPlaylistModal .playlistImage > div.onePlaylistImage > div.onethImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
}
div.home .editPlaylistModal .playlistImage > div.onePlaylistImage  div.centerOfOne {
    font-size: 100px;
}
div.home .editPlaylistModal .playlistImage {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0;
    font-family: CircularSpotifyMedium;
    color: #FFFFFF;
    border-radius: 5px;
    /* overflow: hidden; */
}
div.home .editPlaylistModal .playlistImage div.changePhotoOverlay {
    display: none; 
}
div.home .editPlaylistModal .playlistImage:hover div.changePhotoOverlay {
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:rgb(0,0,0,.5);
    z-index: 2;
    width: 180px;
    height: 180px;
    top: 0;
}
div.home .editPlaylistModal .playlistImage h4 {
    font-size: 15px;
    text-decoration: none;
    cursor: default;
}

.bigButtons .detailsUl {
    position: absolute;
    background-color: #282828;
    width: 213.84px;
    left: 24px;
    top: 32px;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px;
    border-radius: 4px;
    z-index: 2;
    font-family: CircularSpotifyLight;
}
.bigButtons .detailsUl > li {
    padding: 12px 8px 12px 12px;
    border-radius: 4px;
}
.bigButtons .detailsUl > li.barUnder {
    border-bottom: 1px solid rgba(61, 61, 61, .8);
}
.bigButtons .detailsUl > hr {
    padding: 0;
}
.bigButtons .detailsUl > li:hover {
    background-color: #3d3d3d;
}
.bigButtons .detailsUl > li > span {
    cursor: default;
    display: flex;
    flex-direction: row;
    /* justify-content:left; */
    align-items: center;
    vertical-align: middle;
    gap: 12px;
}
