.playbar {
    position: fixed;
    bottom: 0;
    height: 88px;
    flex: 0 1 88px;
    width: 100%;
    background-color: #000000;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FDFDFD;
    z-index: -1;
}

.playbar .leftSide {
    padding: 12px;
    height: 75%;
    width: 31%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
}
.playbar .leftSide img {
    height: 56px;
    width: 56px;
    border-radius: 4px;
}
.playbar .leftSide .trackImage {
    padding-left: 4px;
}
.playbar .leftSide .trackInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 8px;
}
.playbar .leftSide h3{
    font-family: CircularSpotifyLight;
    color: #FFFFFF;
    font-size: 14px;
}
.playbar .leftSide h4 {
    font-family: CircularSpotifyLight;
    color: #B3B3B3;
    font-size: 11px;
}
.playbar .leftSide h3:hover, h4:hover {
    text-decoration: underline;
    color: #FFFFFF;
    cursor: pointer;
}
.playbar .middle {
    padding: 12px;
    height: 75%;
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.playbar .rightSide {
    padding: 12px;
    height: 75%;
    width: 31%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.playbar .middleTop {
    width: auto;
    font-size: 19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #A7A7A7;
}
.playbar .fa-solid:hover {
    color: #FDFDFD;
}
.playPause {
    font-size: 32px;
    color: #FDFDFD;
}
.playPause:hover {
    font-size: 32px;
    color: #FDFDFD;
    transform: scale(1.03);
}
.playbar .fa-solid {
    margin-right: 13px;
    margin-left: 13px;
}
.playbar .trackContainer {
    width: 100%;
    height: 12px;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
}
.playbar .track {
    position: relative;
    height: 4px;
    width: 100%;
    background: #4D4D4D;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.playbar .knob {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    margin-top: -1px;
    transform: translate(-25%, -25%);
    z-index: 4;
}
.playbar .range {
    position: absolute;
    height: 4px;
    background: #FFFFFF;
    border-radius: 4px;
    z-index: 3;
}
.playbar .middleBottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-family: CircularSpotifyLight;
    font-size: 11px;
    color: #B3B3B3;
}

.playbar .middleBottom > div:first-child {
    width: 40px;
    text-align: right;
}
.playbar .middleBottom > div:last-child {
    width: 40px;
    text-align: left;
}

.playbar .volumeTrackContainer {
    width: 93px;
    height: 12px;
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
}
.playbar .volumeTrack {
    position: relative;
    height: 4px;
    width: 100%;
    background: #4D4D4D;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.playbar .volumeKnob {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    margin-top: -1px;
    transform: translate(-25%, -25%);
    z-index: 4;
}
.playbar .volumeRange {
    position: absolute;
    height: 4px;
    background: #FFFFFF;
    border-radius: 4px;
    z-index: 3;
}

.playbar .volumeSymbol {
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.playbar .signUpBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);
    height: 72px;
    margin: auto;
    background: linear-gradient(to right, #B03D96, #509BF5);
    cursor: pointer;
}

.playbar .signUpBarText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
}

.playbar .signUpBarText h7 {
    font-family: CircularSpotifyLight;
    font-size: 12px;
    color: #FFFFFF;
}
.playbar .signUpBarText h6 {
    font-family: CircularSpotifyLight;
    font-size: 16px;
    color: #FFFFFF;
}

.playbar .signUpBar .barButtonContainer {
    padding-right: 24px;
}

.playbar .signUpBar button {
    background-color: #FFFFFF;
    width: 153.81px;
    height: 48px;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
}