.homeLayout {
}
/* section {
    flex: 1 1;
} */
.left {
    flex: 0 0;
    flex-basis: 280px;
    position: sticky;
    height: inherit;
    display: flex;
    width: auto;
    max-width: calc(100% - 448px);
    flex-direction: column;
    padding: 8px 0 0 8px;
}
.right {
    flex: 1 1 auto;
    height: inherit;
    position: sticky;
    /* max-width: calc(100% - 304px); */
    padding: 8px 8px 0 0;
}
.homeTop {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: auto;
    top: 0;
    bottom: 88px;
    width: 100%;
}